<template>
	<div class="topBox">
		<div class="system-title">
			<div class="title-name">历史文化街区和历史建筑数据信息平台（名城名镇名村综合管理平台）</div>
			<p class="title-label">中华人民共和国住房和城乡建设部</p>
		</div>
		<div class="menu-box">
			<div class="menu-item" v-for="(item, index) in menu" :key="index" @click="toPage(item)"
				:class="activeIndex == item.index ? 'active' : ''" v-show="hideMenu(item)">
				<el-popover placement="bottom" width="160" trigger="hover" :offset="200"
					:disabled="item.index != 2 && item.index != 3">
					<template v-if="item.index == 2">
						<div class="popover-box">
							<div class="row" @click="toLSJZ()">
								<span class="iconfont row-icon icon-xiugaimima"></span>历史建筑
							</div>
							<div class="row" @click="$router.push(item.route); activeIndex = item.index;">
								<span class="iconfont row-icon icon-xiugaimima"></span>名镇名村
							</div>
						</div>
						<span slot="reference" v-if="item.index == 2">{{ item.text }}</span>
					</template>
					<template v-if="item.index == 3">
						<div class="popover-box">
							<div class="row" @click="toMCTJ()">
								<span class="iconfont row-icon icon-xiugaimima"></span>名城体检
							</div>
							<div class="row">
								<span class="iconfont row-icon icon-xiugaimima"></span>图斑比对
							</div>
						</div>
						<span slot="reference" v-if="item.index == 3">{{ item.text }}</span>
					</template>
				</el-popover>
				<el-popover placement="bottom" width="180" trigger="hover" :offset="200"
					:disabled="item.index != 4">
					<template v-if="item.index == 4">
						<div class="popover-box">
							<div class="row" v-for="protect in protectList" @click="toGHSC(protect)">
								<span class="iconfont row-icon icon-xiugaimima"></span>{{protect.text}}规划审查
							</div>
						</div>
						<span slot="reference" v-if="item.index == 4">{{ item.text }}</span>
					</template>
				</el-popover>
				<el-popover placement="bottom-end" width="160" trigger="hover" :offset="200" :disabled="item.index != 7">
					<div class="popover-box">
						<div class="row" @click="$router.push('/personalCenter/userManagement')"
							v-if="roleId == 2 || roleId == 3">
							<span class="iconfont row-icon icon-jibenxinxi"></span>用户管理
						</div>
						<div class="row" @click="
							$router.push(
								roleId == 4
									? '/personalCenter/expertInfo'
									: '/personalCenter/mineInfo'
							)
							" v-else>
							<span class="iconfont row-icon icon-jibenxinxi"></span>基本信息
						</div>
						<div class="row" @click="$router.push('/personalCenter/changePassword')">
							<span class="iconfont row-icon icon-xiugaimima"></span>修改密码
						</div>
						<div class="row" @click="logout()">
							<span class="iconfont row-icon icon-tuichu"></span>退出登录
						</div>
					</div>
					<span slot="reference" v-if="item.index == 7">{{ item.text }}</span>
				</el-popover>
				<span v-if="item.index != 2 && item.index != 3 && item.index != 7 && item.index != 4">
					{{ item.text }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["roleId","protectList"]),
		curRoute() {
			return this.$route
		}
	},
	watch: {
		roleId(val) {
			this.menu = [
				{
					text: "首页",
					route: "/systemPage?code=1",
					key: "Home",
					index: 1,
				},
				{
					text: "资源普查",
					route: '/ResourcesCensus/cityAndCounty/indexPage',
					key: 'ResourcesCensus',
					index: 2,
				},
				{
					text: "日常监督",
					route: "",
					index: 3,
				},
				{
					text: "规划编制",
					route: "", // /PlanningPrepare?key=infoOverview
					key: "PlanningPrepare",
					index: 4,
				},
				{
					text: "专项工作",
					route: "",
					index: 5,
				},
				{
					text: "文件档案",
					route: "",
					index: 6,
				},
				{
					text: "个人中心",
					route: "",
					key: "personalCenter",
					index: 7,
				},
			]
			if (val == 6) {
				this.menu = this.menu.map(item => {
					if (item.index == 2) {
						return {
							...item,
							route: '/ResourcesCensus/cityAndCounty/indexPage'
						}
					} else return item
				})
			}
			if (val == 5) {
				this.menu = this.menu.map(item => {
					if (item.index == 2) {
						return {
							...item,
							route: '/ResourcesCensus/province/indexPage'
						}
					} else return item
				})
			}
			if (val == 4) {
				this.menu = this.menu.map(item => {
					if (item.index == 2) {
						return {
							...item,
							route: '/ResourcesCensus/expert/indexPage'
						}
					} else return item
				})
			}
			if (val == 2 || val == 3 || val == 8) {
				this.menu = this.menu.map(item => {
					if (item.index == 2) {
						return {
							...item,
							route: '/ResourcesCensus/ministry/indexPage?key=baseInfoTown'
						}
					} else return item
				})
			}
			if (val == 2 || val == 3) {
				this.menu = this.menu.map(item => {
					if (item.index == 1) {
						return {
							...item,
							route: '/systemPage/planningMap'
						}
					} else return item
				})
			}
			var path = this.$route.path
			if (path) {
				var arr = this.menu.filter(item => item.route == path)
				if (arr.length > 0) {
					this.activeIndex = arr[0].index
				}
			}
		},
		curRoute(data) {
			if (data.path) {
				var arr = this.menu.filter(item => data.path.indexOf(item.key) > -1)
				if (arr.length > 0) {
					this.activeIndex = arr[0].index
				}
			}
		},
	},
	data() {
		return {
			activeIndex: null,
			menu: [],
		};
	},
	methods: {
		// 打开新页面（历史建筑）
		toLSJZ(){
			return false
			// 专家用户、文物局用户不让跳转
			if (this.roleId == 4 || this.roleId == 8) return
			let HREF = window.config.href.LSJZ
			if (HREF) {
				window.location.href = HREF
			}
		},
		// 打开新页面（名城体检）
		toMCTJ() {
			// 专家用户、文物局用户不让跳转
			if (this.roleId == 4 || this.roleId == 8) return
			let HREF = window.config.href.MCTJ
			if (HREF) {
				window.location.href = HREF
			}
		},
		// 菜单权限控制
		hideMenu(item) {
			if (this.roleId == 8 && item.index == 4) {
				return false
			}
			return true
		},
		// 路由页面跳转
		toPage(item) {
			if (item.route == null || item.route == "" || item.index == 2) return;
			this.activeIndex = item.index;
			this.$router.push(item.route);
		},
		// 注销
		logout() {
			this.$apis.logout().then((res) => {
				if (res.body) {
					this.$router.push("/login");
					window.localStorage.removeItem("hccms_token");
				} else {
					this.$message.warning('注销失败')
				}
			})
		},
		toGHSC(item){
			this.$store.commit('setTypeInfo',item);
			if(this.$route.path == '/PlanningPrepare' && this.$route.query.key == 'infoOverview'){
				this.$router.go(0)
			} else {
				this.$router.push(`/PlanningPrepare?key=infoOverview`);
			}
		}
	},
	mounted() { },
};
</script>
<style lang="less">
.topBox {
	width: 100vw;
	min-width: 1480px;
	height: 60px;
	background-color: #8b7a9b;

	&::after {
		width: 100%;
		height: 0;
		line-height: 0;
		font-size: 0;
		clear: both;
	}

	.system-title {
		float: left;
		margin-left: 20px;

		.title-name {
			font-size: 24px;
			margin-top: 5px;
			color: #f2f2f2;
			font-weight: bold;
			line-height: 30px;
		}

		.title-label {
			font-size: 14px;
			color: #f2f2f2;
			line-height: 21px;
			transform: scale(1, 0.9);
			letter-spacing: 2px;
		}
	}

	.menu-box {
		width: 700px;
		float: right;
		display: flex;

		.menu-item {
			flex: 1;
			line-height: 60px;
			font-size: 20px;
			text-align: center;
			color: #f2f2f2;
			cursor: pointer;

			&.active {
				background-color: #674b6d;
			}

			&:hover {
				background-color: #674b6d;
			}
		}
	}
}
</style>