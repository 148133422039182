import request from "@/utils/request.js";

export default {
  // 用户登录
  login(data) {
    return request.Put({
      url: "/v1/authentication/classic",
      data: data
    });
  },
  // 重置指定用户密码
  resetPwd(data, userId) {
    return request.Post({
      url: `/v1/authentication/classic-secret/reset/${userId}`,
      data: data
    });
  },
  // 用户注销登录
  logout(data = {}) {
    return request.Delete({
      url: "/v1/authentication/sign-out",
      data: data
    });
  },
  // OAuth用户登录
  oauth2Code(appid, state) {
    return request.Get({
      url: `/v1/authentication/connect?response_type=code&scope=openid&appid=${appid}&state=${state}`
    });
  },
  // 修改密码
  changePwd(data) {
    return request.Post({
      url: "/v1/authentication/classic-secret/modify",
      data: data
    });
  },
  // 上传文件
  uploadFile(file) {
    return request.Post({
      url: "/v1/system/file-storage/upload",
      data: {
        type: "file",
        file
      }
    });
  },
  // 预览文件
  reviewFile(fileSummaryStr) {
    return request.Get({
      url: "/v1/system/file-storage/summary/" + fileSummaryStr,
      data: {}
    });
  },
  // 下载文件
  downloadFile(uid) {
    return request.Download({
      url: "/v1/system/file-storage/download/" + uid,
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 部级用户下载文件
  ministryDownloadFile(uid) {
    return request.Download({
      url: "/v1/cp/planning/expert/opinion/ministry/download/" + uid,
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 获取当前用户菜单树
  getMineMenu() {
    return request.Get({
      url: "/v1/system/user/my-facade",
      data: {}
    });
  },
  // 获取当前用户个人信息
  getMineInfo() {
    return request.Get({
      url: "/v1/system/user/my",
      data: {}
    });
  },
  // 编辑当前机构用户的信息
  editInstitutionUser(data) {
    return request.Put({
      url: "/v1/system/user/my/institution",
      data: data
    });
  },
  // 编辑当前专家用户的信息
  editExpertUser(data) {
    return request.Put({
      url: "/v1/system/user/my/expert",
      data: data
    });
  },
  // 获取可申报的行政区划树（带父级）
  getRegionTree() {
    return request.Get({
      url: "/v1/standard/region/declare",
      data: {}
    });
  },
  // 通过父级code查子级行政区划
  getRegionByCode(data) {
    return request.Post({
      url: "/v1/standard/region/list",
      data: data
    });
  },
  // 暂存-名镇申报-表格整体信息
  saveTownBasic(data) {
    return request.Post({
      url: "/v1/hcc/declare/town/basic",
      data: data
    });
  },
  // 暂存-名村申报-表格整体信息
  saveVillageBasic(data) {
    return request.Post({
      url: "/v1/hcc/declare/village/basic",
      data: data
    });
  },
  // 修改-名镇申报-表格整体信息
  editTownBasic(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/town/basic/" + declareId,
      data: data
    });
  },
  // 修改-名村申报-表格整体信息
  editVillageBasic(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/village/basic/" + declareId,
      data: data
    });
  },
  // 详情-名镇申报-表格整体信息
  getTownBasic(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/town/basic/" + declareId,
      data: {}
    });
  },
  // 详情-名村申报-表格整体信息
  getVillageBasic(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/village/basic/" + declareId,
      data: {}
    });
  },
  // // 暂存-名镇申报-申请报告
  // saveTownApplicationReport(data, declareId) {
  //   return request.Put({
  //     url: '/v1/hcc/declare/town/apply-report/' + declareId,
  //     data: data
  //   })
  // },
  // 暂存-名镇申报-申请报告
  saveTownApplicationReport(data, declareId) {
    return request.Put({
      url: "/v2/hcc/declare/town/apply-report/" + declareId,
      data: data
    });
  },
  // 暂存-名村申报-申请报告
  saveVillageApplicationReport(data, declareId) {
    return request.Put({
      url: "/v2/hcc/declare/village/apply-report/" + declareId,
      data: data
    });
  },
  // // 暂存-名村申报-申请报告
  // saveVillageApplicationReport(data, declareId) {
  //   return request.Put({
  //     url: '/v1/hcc/declare/village/apply-report/' + declareId,
  //     data: data
  //   })
  // },
  // // 详情-名镇申报-申请报告
  // getTownApplicationReport(declareId) {
  //   return request.Get({
  //     url: '/v1/hcc/declare/town/apply-report/' + declareId,
  //     data: {}
  //   })
  // },
  // 详情-名镇申报-申请报告
  getTownApplicationReport(declareId) {
    return request.Get({
      url: "/v2/hcc/declare/town/apply-report/" + declareId,
      data: {}
    });
  },
  // 详情-名村申报-申请报告
  // getVillageApplicationReport(declareId) {
  //   return request.Get({
  //     url: '/v1/hcc/declare/village/apply-report/' + declareId,
  //     data: {}
  //   })
  // },
  // 详情-名村申报-申请报告
  getVillageApplicationReport(declareId) {
    return request.Get({
      url: "/v2/hcc/declare/village/apply-report/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇申报-保护规划
  saveTownProtectPlan(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/town/conservation-planning/" + declareId,
      data: data
    });
  },
  // 详情-名镇申报-保护规划
  getTownProtectPlan(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/town/conservation-planning/" + declareId,
      data: {}
    });
  },
  // 暂存-名村申报-保护规划
  saveVillageProtectPlan(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/village/conservation-planning/" + declareId,
      data: data
    });
  },
  // 详情-名村申报-保护规划
  getVillageProtectPlan(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/village/conservation-planning/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇申报-保护措施/保护利用制度
  saveTownProtectMeasure(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/town/conservation-measure/" + declareId,
      data: data
    });
  },
  // 详情-名镇申报-保护措施/保护利用制度
  getTownProtectMeasure(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/town/conservation-measure/" + declareId,
      data: {}
    });
  },
  // 暂存-名村申报-保护措施/保护利用制度
  saveVillageProtectMeasure(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/village/conservation-measure/" + declareId,
      data: data
    });
  },
  // 详情-名村申报-保护措施/保护利用制度
  getVillageProtectMeasure(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/village/conservation-measure/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇申报-多媒体
  saveTownMediaFile(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/town/multimedia/" + declareId,
      data: data
    });
  },
  // 详情-名镇申报-多媒体
  getTownMediaFile(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/town/multimedia/" + declareId,
      data: {}
    });
  },
  // 暂存-名村申报-多媒体
  saveVillageMediaFile(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/village/multimedia/" + declareId,
      data: data
    });
  },
  // 详情-名村申报-多媒体
  getVillageMediaFile(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/village/multimedia/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇申报-填报人信息
  saveTownFillingInfo(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/town/handler/" + declareId,
      data: data
    });
  },
  // 详情-名镇申报-填报人信息
  getTownFillingInfo(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/town/handler/" + declareId,
      data: {}
    });
  },
  // 暂存-名村申报-填报人信息
  saveVillageFillingInfo(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/village/handler/" + declareId,
      data: data
    });
  },
  // 详情-名村申报-填报人信息
  getVillageFillingInfo(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/village/handler/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇名村申报-基础数据表格
  saveBaseInfoTable(data, declareId) {
    return request.Post({
      url: "/v1/hcc/declare/table/base/" + declareId,
      data: data
    });
  },
  // 提交-名镇名村申报-基础数据表格
  postBaseInfoTable(data, declareId) {
    return request.Post({
      url: "/v1/hcc/declare/table/base/submit/" + declareId,
      data: data
    });
  },
  // 获取省级审批与反馈信息列表
  getTownOther(declareId) {
    return request.Get({
      url: "/v1/hcc/approval/approval-and-feedback/" + declareId,
      data: {}
    });
  },
  // 详情-名镇名村申报-基础数据表格
  getBaseInfoTable(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/table/base/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇名村申报-申报表数据表格
  saveApplyTable(data, declareId) {
    return request.Post({
      url: "/v1/hcc/declare/table/apply/" + declareId,
      data: data
    });
  },
  // 提交-名镇名村申报-申报表数据表格
  postApplyTable(data, declareId) {
    return request.Post({
      url: "/v1/hcc/declare/table/apply/submit/" + declareId,
      data: data
    });
  },
  // 详情-名镇名村申报-申报表数据表格
  getApplyTable(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/table/apply/" + declareId,
      data: {}
    });
  },
  // 暂存-名镇名村申报-自评表表格
  saveEvaluateSystem(data, declareId) {
    return request.Post({
      url: "/v1/hcc/declare/table/evaluation/" + declareId,
      data: data
    });
  },
  // 提交-名镇名村申报-自评表表格
  postEvaluateSystem(data, declareId) {
    return request.Post({
      url: "/v1/hcc/declare/table/evaluation/submit/" + declareId,
      data: data
    });
  },
  // 详情-名镇名村申报-自评表表格
  getEvaluateSystem(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/table/evaluation/" + declareId,
      data: {}
    });
  },
  // 提交-名镇名村申报
  submitDeclareForm(declareId) {
    return request.Post({
      url: "/v1/hcc/declare/submit/" + declareId,
      data: {}
    });
  },
  // 申报-删除
  delDeclare(declareId) {
    return request.Delete({
      url: "/v1/hcc/declare/" + declareId,
      data: {}
    });
  },
  // 名镇-分页列表
  getTownPage(data) {
    return request.Post({
      url: "/v1/hcc/declare/town",
      data: data
    });
  },
  // 名村-分页列表
  getVillagePage(data) {
    return request.Post({
      url: "/v1/hcc/declare/village",
      data: data
    });
  },
  // 申报-基本信息
  getDeclareCount(data) {
    return request.Get({
      url: "/v1/hcc/declare/statistics",
      data: data
    });
  },
  // 审核
  audit(declareId) {
    return request.Post({
      url: "/v1/hcc/declare/submit/" + declareId,
      data: {}
    });
  },
  // 申报-完整性校验
  checkDeclare(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/completion/" + declareId,
      data: {}
    });
  },
  // 获取-流程可选择状态
  getProgressStatus() {
    return request.Get({
      url: "/v1/system/enum/declare-status",
      data: {}
    });
  },
  // 名镇-省级审核-分页列表
  getTownProvincePage(data) {
    return request.Post({
      url: "/v1/hcc/declare/town/approving",
      data: data
    });
  },
  // 名村-省级审核-分页列表
  getVillageProvincePage(data) {
    return request.Post({
      url: "/v1/hcc/declare/village/approving",
      data: data
    });
  },
  // 提交-审核信息
  setProvinceInfo(data, declareId) {
    return request.Put({
      url: "/v1/hcc/approval/province/" + declareId,
      data: data
    });
  },
  // 读取省级审核信息
  getProvinceInfo(declareId) {
    return request.Get({
      url: "/v1/hcc/approval/province/" + declareId,
      data: {}
    });
  },
  // 提交-省级问题反馈
  setProblemInfo(data, declareId) {
    return request.Put({
      url: "/v1/hcc/approval/feedback/" + declareId,
      data: data
    });
  },
  // 读取-省级问题反馈
  getProblemInfo(declareId) {
    return request.Get({
      url: "/v1/hcc/approval/feedback/" + declareId,
      data: {}
    });
  },

  // 初审专家-名镇申报列表
  getfirstTownList(data) {
    return request.Post({
      url: "/v1/hcc/declare/expert-approval/town/audit-first",
      data: data
    });
  },
  // 初审专家-名存申报列表
  getfirstVillageList(data) {
    return request.Post({
      url: "/v1/hcc/declare/expert-approval/village/audit-first",
      data: data
    });
  },
  // 复核专家-名镇申报列表
  getSecondTownList(data) {
    return request.Post({
      url: "/v1/hcc/declare/expert-approval/town/audit-again",
      data: data
    });
  },
  // 复核专家-名存申报列表
  getSecondVillageList(data) {
    return request.Post({
      url: "/v1/hcc/declare/expert-approval/village/audit-again",
      data: data
    });
  },
  // 初审专家-提交名镇初审意见
  postFirstTown(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/expert-approval/town/submit/audit-first/" + declareId,
      data: data
    });
  },
  // 初审专家-提交名村初审意见
  postFirstVillage(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/expert-approval/village/submit/audit-first/" + declareId,
      data: data
    });
  },
  // 复核专家-提交名镇复核意见
  postSecondTown(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/expert-approval/town/submit/audit-again/" + declareId,
      data: data
    });
  },
  // 复核专家-提交名村复核意见
  postSecondVillage(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/expert-approval/village/submit/audit-again/" + declareId,
      data: data
    });
  },
  // 获取专家审核意见
  getExpertApproval(data, declareId) {
    return request.Get({
      url: "/v1/hcc/declare/expert-approval/" + declareId,
      data: data
    });
  },
  // 部级专家用户-申报基本信息
  getExpertBaseInfo() {
    return request.Get({
      url: "/v1/hcc/declare/ministry-expert/statistics",
      data: {}
    });
  },
  // 获取初审专家审核意见列表
  getFirstExpert(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/expert-approval/audit-first/" + declareId,
      data: {}
    });
  },
  // 获取复核专家审核意见列表
  getSecondExpert(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/expert-approval/audit-again/" + declareId,
      data: {}
    });
  },
  // 获取部级审定名镇列表
  getMinistryTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/approval/town/list",
      data: data
    });
  },
  // 获取部级审定名村列表
  getMinistryVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/approval/village/list",
      data: data
    });
  },
  // 提交-部级审定
  postMinistry(data, declareId) {
    return request.Put({
      url: "/v1/hcc/declare/municipal/approval/" + declareId,
      data: data
    });
  },
  // 获取省级审批与反馈信息列表
  getProvinceApproval(declareId) {
    return request.Get({
      url: "/v1/hcc/approval/approval-and-feedback/" + declareId,
      data: {}
    });
  },
  // 获取市县填报与省级审批列表
  getDeclareApproval(declareId) {
    return request.Get({
      url: "/v1/hcc/approval/declare-and-approval/" + declareId,
      data: {}
    });
  },
  // 获取专家列表
  getExpertPage(data) {
    return request.Post({
      url: "/v1/system/user/expert/page",
      data: data
    });
  },
  // 专家-新增
  addExpert(data) {
    return request.Post({
      url: "/v1/system/user/expert",
      data: data
    });
  },
  // 专家-编辑
  editExpert(data, id) {
    return request.Put({
      url: "/v1/system/user/expert/" + id,
      data: data
    });
  },
  // 专家-详情
  getExpert(id) {
    return request.Get({
      url: "/v1/system/user/expert/" + id,
      data: {}
    });
  },
  // 专家-删除
  delExpert(id) {
    return request.Delete({
      url: "/v1/system/user/expert/" + id,
      data: {}
    });
  },
  // 待遴选-初审-名镇
  unselectedFirstTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/town/unselected",
      data: data
    });
  },
  // 待遴选-复核-名镇
  unselectedSecondTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/town/unselected",
      data: data
    });
  },
  // 待遴选-初审-名村
  unselectedFirstVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/village/unselected",
      data: data
    });
  },
  // 待遴选-复核-名村
  unselectedSecondVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/village/unselected",
      data: data
    });
  },
  // 已遴选-初审-名镇
  selectedFirstTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/town/selected",
      data: data
    });
  },
  // 已遴选-复核-名镇
  selectedSecondTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/town/selected",
      data: data
    });
  },
  // 已遴选-初审-名村
  selectedFirstVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/village/selected",
      data: data
    });
  },
  // 已遴选-复核-名村
  selectedSecondVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/village/selected",
      data: data
    });
  },
  // 初审中-初审-名镇
  approvalFirstTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/town",
      data: data
    });
  },
  // 复核中-复核-名镇
  approvalSecondTown(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/town",
      data: data
    });
  },
  // 初审中-初审-名村
  approvalFirstVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/village",
      data: data
    });
  },
  // 复核中-复核-名村
  approvalSecondVillage(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/village",
      data: data
    });
  },

  // 待遴选-初审-名镇-可选省份
  unselectedFirstTownRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/town/unselected/region",
      data: data
    });
  },
  // 待遴选-复核-名镇-可选省份
  unselectedSecondTownRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/town/unselected/region",
      data: data
    });
  },
  // 待遴选-初审-名村-可选省份
  unselectedFirstVillageRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/village/unselected/region",
      data: data
    });
  },
  // 待遴选-复核-名村-可选省份
  unselectedSecondVillageRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/village/unselected/region",
      data: data
    });
  },
  // 已遴选-初审-名镇-可选省份
  selectedFirstTownRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/town/selected/region",
      data: data
    });
  },
  // 已遴选-复核-名镇-可选省份
  selectedSecondTownRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/town/selected/region",
      data: data
    });
  },
  // 已遴选-初审-名村-可选省份
  selectedFirstVillageRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/village/selected/region",
      data: data
    });
  },
  // 已遴选-复核-名村-可选省份
  selectedSecondVillageRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/village/selected/region",
      data: data
    });
  },
  // 初审中-初审-名镇-可选省份
  approvalFirstTownRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/town/region",
      data: data
    });
  },
  // 初审中-复核-名镇-可选省份
  approvalSecondTownRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/town/region",
      data: data
    });
  },
  // 初审中-初审-名村-可选省份
  approvalFirstVillageRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/village/region",
      data: data
    });
  },
  // 初审中-复核-名村-可选省份
  approvalSecondVillageRegion(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/village/region",
      data: data
    });
  },
  // 部级用户-遴选初审专家暂存
  saveFirstExpert(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/expert-select/inactive",
      data: data
    });
  },
  // 部级用户-提交初审专家遴选
  postFirstExpert(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-first/expert-select",
      data: data
    });
  },
  // 部级用户-遴选复核专家暂存
  saveSecondExpert(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/expert-select/inactive",
      data: data
    });
  },
  // 部级用户-提交复核专家遴选
  postSecondExpert(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/audit-again/expert-select",
      data: data
    });
  },
  // 部级用户-获取初审专家列表
  getFirstExpertList(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/expert-select/audit-first/expert-list",
      data: data
    });
  },
  // 部级用户-获取复核专家列表
  getSecondExpertList(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/expert-select/audit-again/expert-list",
      data: data
    });
  },
  // 部级用户-获取已遴选初审专家
  getFirstExpertSelect(declareId, search) {
    return request.Get({
      url: `/v1/hcc/declare/municipal/audit-first/expert-select/${declareId}?search=${search}`,
      data: {}
    });
  },
  // 部级用户-获取已遴选复核专家
  getSecondExpertSelect(declareId, search) {
    return request.Get({
      url: `/v1/hcc/declare/municipal/audit-again/expert-select/${declareId}?search=${search}`,
      data: {}
    });
  },
  // 获取各省名镇提请建议与审核列表
  getTownAdviseList(data) {
    return request.Post({
      url: "/v1/hcc/declare/ministry/count-proportion/town",
      data: data
    });
  },
  // 获取各省名镇提请建议与审核列表
  getVillageAdviseList(data) {
    return request.Post({
      url: "/v1/hcc/declare/ministry/count-proportion/village",
      data: data
    });
  },
  // 部级用户-申报基本信息
  getMinistryInfo() {
    return request.Get({
      url: "/v1/hcc/declare/ministry/statistics",
      data: {}
    });
  },
  // 部级用户-获取名镇数据汇总
  getTownTotal(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/town/ministry",
      data: data
    });
  },
  // 部级用户-获取名村数据汇总
  getVillageTotal(data) {
    return request.Post({
      url: "/v1/hcc/declare/municipal/village/ministry",
      data: data
    });
  },
  // 消息列表
  getMessageList(data) {
    return request.Post({
      url: "/v1/hcc/message/page",
      data: data
    });
  },
  // 消息详情
  getMessageDetial(id) {
    return request.Post({
      url: "/v1/hcc/message/" + id,
      data: {}
    });
  },
  // 获取名镇名村专家建议结论
  getPublicResult(declareId) {
    return request.Get({
      url: "/v1/hcc/declare/municipal/conclusion/" + declareId,
      data: {}
    });
  },
  // 下载专家导入模板文件
  getImportFile() {
    return request.Download({
      url: "/v1/common/template/expert-import",
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 专家意见模板下载
  getOpinionFile() {
    return request.Download({
      url: "/v1/common/template/expert-opinion",
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 导出专家库
  getExportFile() {
    return request.Download({
      url: "/v1/system/expert/export",
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 部级用户-导出数据统计
  exportMinistry() {
    return request.Download({
      url: "/v1/common/export/ministry-statistics",
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 下载申请报告模板文件
  getApplyReportFile() {
    return request.Download({
      url: "/v1/common/template/apply-report",
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 解析专家excel文件
  getParseFile(data) {
    return request.Post({
      url: "/v1/system/expert/parsing",
      data: data
    });
  },
  // 批量添加专家
  saveBatchFile(data) {
    return request.Post({
      url: "/v1/system/expert/batch",
      data: data
    });
  },
  // 单独校验某个专家的合法性
  verifyExpert(data) {
    return request.Post({
      url: "/v1/system/expert/verify",
      data: data
    });
  },
  // 部级个人中心-获取用户总量统计
  getUserTotalStatistical(regionCode) {
    return request.Get({
      url: "/v1/system/personal-center/user-total-statistical/" + regionCode,
      data: {}
    });
  },
  // 部级个人中心-获取用户总量统计（区分行政区划）
  getUserTotalStatisticalList(regionCode) {
    return request.Get({
      url: "/v1/system/personal-center/user-total-statistical-list/" + regionCode,
      data: {}
    });
  },
  // 部级个人中心-根据行政区划获取用户列表
  getUserList(regionCode) {
    return request.Get({
      url: "/v1/system/personal-center/user-list/" + regionCode,
      data: {}
    });
  },
  // 部级个人中心-添加市县/省级用户
  getAddUser(data) {
    return request.Post({
      url: "/v1/system/personal-center/user-add",
      data: data
    });
  },
  // 部级个人中心-修改市县/省级用户
  putAddUser(data, userId) {
    return request.Put({
      url: "/v1/system/personal-center/user-change/" + userId,
      data: data
    });
  },
  // 部级用户个人中心-重置市县/省级用户密码
  resetPassword(userId) {
    return request.Put({
      url: "/v1/system/personal-center/password-reset/" + userId,
      data: {}
    });
  },
  // 删除用户
  delUser(userId) {
    return request.Delete({
      url: "/v1/system/user/" + userId,
      data: {}
    });
  },
  // 获取部级用户
  getMinistryList() {
    return request.Get({
      url: "/v1/system/personal-center/user-municipal-list",
      data: {}
    });
  },
  // 批量添加部级用户
  addMinistryList(data) {
    return request.Post({
      url: "/v1/system/personal-center/user-municipal-add",
      data: data
    });
  },
  // 批量删除部级用户
  delMinistryList(data) {
    return request.Delete({
      url: "/v1/system/personal-center/user-municipal",
      data: data
    });
  },
  // 获取国家文物局用户
  getWWJList() {
    return request.Get({
      url: "/v1/system/personal-center/user-historic-list",
      data: {}
    });
  },
  // 批量添加国家文物局用户
  addWWJList(data) {
    return request.Post({
      url: "/v1/system/personal-center/user-historic-add",
      data: data
    });
  },
  // 批量删除国家文物局用户
  delWWJList(data) {
    return request.Delete({
      url: "/v1/system/personal-center/user-historic",
      data: data
    });
  },
  // 重置密码国家文物局用户
  resetPwdWWJ(data, userId) {
    return request.Put({
      url: `/v1/system/personal-center/password-reset/historic/${userId}`,
      data: data
    });
  },
  // 第三方名城用户-分页
  getThirdPartyPage(data) {
    return request.Post({
      url: "/v1/system/personal-center/user-cultural/page",
      data
    });
  },
  // 添加第三方名城评估用户的用户信息
  // addThirdPartyUser(data) {
  //   return request.Post({
  //     url: "/v1/system/user-cultural/user-cultural-add",
  //     data
  //   });
  // },
  // 获取行政区
  getCulturalCity(regionCode) {
    return request.Get({
      url: `/v1/system/personal-center/cultural-city/${regionCode}`,
    });
  },
  //修改行政区
  modifyCulturalCity(data) {
    return request.Put({
      url: "/v1/system/personal-center/region-modify",
      data
    });
  },
  // 获取第三方名城用户的用户信息
  getThirdPartyUserInfo(regionId) {
    return request.Get({
      url: `/v1/system/personal-center/user-cultural/${regionId}`,
    });
  },
  // 修改第三方名城用户信息
  mofidyThirdPartyUserInfo(data,userId){
    return request.Put({
      url: `/v1/system/personal-center/cultural-change/${userId}`,
      data
    })
  },
  
  // ---------------------------------------------------------市县
  // 信息总览分页列表
  getPlanningTownPage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/basic/page",
      data
    });
  },
  // 获取统计
  getTownStatisctics(planningType) {
    return request.Post({
      url: `/v1/cp/planning/declare/town/basic/statistics/${planningType}`,
    });
  },
  // /api{declareId}
  // 删除名称申报
  deleteCityDeclare(declareId) {
    return request.Delete({
      url: "/v1/cp/planning/declare/town/basic/" + declareId,
      data: {}
    });
  },
  // 暂存-名城申报
  saveCityDeclare(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/basic",
      data: data
    });
  },
  // 修改-名城申报
  editCityDeclare(data, declareId) {
    return request.Put({
      url: "/v1/cp/planning/declare/town/basic/" + declareId,
      data: data
    });
  },
  // 详情-名城申报
  getCityDeclare(declareId) {
    return request.Get({
      url: "/v1/cp/planning/declare/town/basic/" + declareId,
      data: {}
    });
  },
  // 暂存-调整说明
  saveCityAdjustment(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/adjustment/" + declareId,
      data: data
    });
  },
  // 详情-调整说明
  getCityAdjustment(declareId) {
    return request.Get({
      url: "/v1/cp/planning/declare/town/adjustment/" + declareId,
      data: {}
    });
  },
  // 详情-调整说明-历史
  getHistoryCityAdjustment(declareId) {
    return request.Get({
      url: "/v1/cp/planning/declare/town/history/adjustment/" + declareId,
      data: {}
    });
  },
  // 暂存-填报人信息
  saveFillingInfo(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/filling/" + declareId,
      data: data
    });
  },
  // 详情-填报人信息
  getFillingInfo(declareId) {
    return request.Get({
      url: "/v1/cp/planning/declare/town/filling/" + declareId,
      data: {}
    });
  },
  // 详情-填报人信息-历史
  getHistoryFillingInfo(declareId) {
    return request.Get({
      url: "/v1/cp/planning/declare/town/history/filling/" + declareId,
      data: {}
    });
  },
  // 提交-名城申报
  submitCityDeclareForm(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/declare/submit/filling/" + declareId,
      data: data
    });
  },
  // 获取规划历版分页
  getHistoryVersionPage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/version/page",
      data: data
    });
  },
  // 获取规划历版分页-历史
  getHistoryHistoryVersionPage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/version/history/page",
      data: data
    });
  },
  // 保存添加规划历版
  saveHistoryVersion(data, declareId) {
    return request.Post({
      url: `/v1/cp/planning/declare/version/town/${declareId}`,
      data: data
    });
  },
  // 获取规划历版
  getHistoryVersion(declareId) {
    return request.Get({
      url: `/v1/cp/planning/declare/version/${declareId}`,
      data: {}
    });
  },
  // 获取规划历版-历史
  getHistoryHistoryVersion(declareId) {
    return request.Get({
      url: `/v1/cp/planning/declare/version/history/${declareId}`,
      data: {}
    });
  },
  // 修改规划历版
  editHistoryVersion(data, declareId) {
    return request.Put({
      url: `/v1/cp/planning/declare/version/town/${declareId}`,
      data: data
    });
  },
  // 删除规划历版
  delHistoryVersion(declareVersionId) {
    return request.Delete({
      url: `/v1/cp/planning/declare/version/${declareVersionId}`,
      data: {}
    });
  },
  // 获取-规划历史分页
  getHistoryPage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/history/page",
      data: data
    });
  },
  // 详情-规划历史
  getHistoryDetail(declareHistoryId) {
    return request.Get({
      url: "/v1/cp/planning/declare/town/history/" + declareHistoryId,
      data: {}
    });
  },
  // 暂存-规划历史
  saveHistory(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/history/" + declareId,
      data: data
    });
  },
  // 修改-规划历史
  editHistory(data, declareHistoryId) {
    return request.Put({
      url: "/v1/cp/planning/declare/town/history/" + declareHistoryId,
      data: data
    });
  },
  // ----------------------------省级
  // 信息总览分页列表
  getPlanningProvincePage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/basic/province/page",
      data: data
    });
  },
  // 获取统计
  getProvinceStatisctics(planningType) {
    return request.Post({
      url: `/v1/cp/planning/declare/town/basic/province/statistics/${planningType}`,
    });
  },
  // 提交-省级审核信息
  setProvincialReview(data, declareId,type) {
    return request.Post({
      url: `/v1/cp/planning/province/review/approval/${type}/` + declareId,
      data: data
    });
  },
  // 读取-省级审核信息
  getProvincialReviewInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/province/review/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 读取-省级审核信息列表
  getProvincialReviewPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/province/review/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 审批记录列表
  getApprovalLogList(data) {
    return request.Post({
      url: "/v1/cp/planning/approval/log/list",
      data: data
    });
  },
  // 审批记录列表-专家
  getApprovalLogListExpert(data) {
    return request.Post({
      url: "/v1/cp/planning/approval/log/expert/list",
      data: data
    });
  },
  // 每个节点通用记录查询样例
  getLogDetail(logId) {
    return request.Get({
      url: `/v1/cp/planning/province/review/log/${logId}`,
      data: {}
    });
  },
  // 提交-技术方案审查
  setTechnicalReview(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/technology/approval/" + declareId,
      data: data
    });
  },
  // 获取-技术方案审查信息
  getTechnicalReviewInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/technology/approval/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 读取-技术方案审查信息列表
  getTechnicalReviewPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/technology/approval/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 提交-省级备案审查
  setProvincialFiling(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/province/filing/review/approval/" + declareId,
      data: data
    });
  },
  // 获取-省级备案信息
  getProvincialFilingInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/province/filing/review/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 读取-省级备案列表
  getProvincialFilingPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/province/filing/review/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // ----------------------------部级
  // 信息总览分页列表
  getPlanningMinistryPage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/basic/nation/page",
      data: data
    });
  },
  // 获取统计
  getMinistryStatisctics(planningType) {
    return request.Post({
      url: `/v1/cp/planning/declare/town/basic/nation/statistics/${planningType}`,
    });
  },
  // 提交-要件审核
  setEssentialsAudit(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/municipal/approval/" + declareId,
      data: data
    });
  },
  // 获取-要件审核信息
  getEssentialsAuditInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/municipal/approval/" + declareId + "/" + declareVersion,
      data: {}
    });
  },

  // 读取-要件审核信息列表
  getEssentialsAuditPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/municipal/approval/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 提交-部级预审
  setMinisterialPreliminary(data, declareId, declareVersion) {
    return request.Post({
      url: "/v1/cp/planning/preliminary/review/approval/" + declareId,
      data: data
    });
  },
  // 获取-部级预审信息
  getMinisterialPreliminaryInfo(declareId, declareVersion, businessType) {
    return request.Get({
      url:
        "/v1/cp/planning/preliminary/review/" +
        declareId +
        "/" +
        declareVersion +
        "/" +
        businessType,
      data: {}
    });
  },

  // 读取-部级预审信息列表
  getMinisterialPreliminaryPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/preliminary/review/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 提交-审核技术审查方案
  setAuditTechnicalReview(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/approval/technology/approval/" + declareId,
      data: data
    });
  },
  // 获取-审核技术审查方案信息
  getAuditTechnicalReviewInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/approval/technology/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 读取-审核技术审查方案信息列表
  getAuditTechnicalReviewPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/approval/technology/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 暂存—部级技术审查
  saveMinisterialTechnicalReview(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/municipal/technology/approval/town/" + declareId,
      data: data
    });
  },
  // 提交-部级技术审查
  setMinisterialTechnicalReview(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/municipal/technology/approval/" + declareId,
      data: data
    });
  },
  // 获取-部级技术审查信息
  getMinisterialTechnicalReviewInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/municipal/technology/approval/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 读取-部级技术审查信息列表
  getMinisterialTechnicalReviewPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/municipal/technology/approval/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },

  // 提交-部级备案审查
  setMinisterialFiling(data, declareId, protectType) {
    return request.Post({
      url: `/v1/cp/planning/municipal/filing/review/approval/${protectType}/` + declareId,
      data: data
    });
  },
  // 获取-部级备案审查信息
  getMinisterialFilingInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/municipal/filing/review/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 读取-部级备案审查列表
  getMinisterialFilingPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/municipal/filing/review/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // ----------------------------专家
  // 信息总览分页列表
  getPlanningExportPage(data) {
    return request.Post({
      url: "/v1/cp/planning/declare/town/basic/expert/page",
      data: data
    });
  },
  // 提交-专家预审意见
  setExpertPreliminary(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/expert/opinion/approval/" + declareId,
      data: data
    });
  },
  // 获取-专家预审意见
  getExpertPreliminaryInfo(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/expert/opinion/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 获取-专家意见
  getExpertPreliminaryPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/expert/opinion/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 获取遴选专家列表
  getCityExpertList(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/selected/expert/list/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 获取-遴选专家-已选列表
  getSelectedExpertPage(declareId, declareVersion) {
    return request.Get({
      url: "/v1/cp/planning/selected/expert/" + declareId + "/" + declareVersion,
      data: {}
    });
  },
  // 提交-遴选专家
  setSelectedExpert(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/selected/expert/commit/" + declareId,
      data: data
    });
  },
  // 暂存-遴选专家
  saveSelectedExpert(data, declareId) {
    return request.Post({
      url: "/v1/cp/planning/selected/expert/town/" + declareId,
      data: data
    });
  },

  // 审批记录详情
  // 读取-省级审核信息列表
  getProvincialReviewPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/province/review/log/" + logId,
      data: {}
    });
  },
  // 读取-要件审核信息列表
  getEssentialsAuditPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/municipal/approval/log/" + logId,
      data: {}
    });
  },
  // 获取遴选专家列表
  getCityExpertListV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/selected/expert/log/" + logId,
      data: {}
    });
  },
  // 获取-专家意见
  getExpertPreliminaryPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/expert/opinion/log/" + logId,
      data: {}
    });
  },
  // 读取-部级预审信息列表
  getMinisterialPreliminaryPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/preliminary/review/log/" + logId,
      data: {}
    });
  },
  // 读取-技术方案审查信息列表
  getTechnicalReviewPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/technology/approval/log/" + logId,
      data: {}
    });
  },
  // 读取-审核技术审查方案信息列表
  getAuditTechnicalReviewPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/approval/technology/log/" + logId,
      data: {}
    });
  },
  // 读取-部级技术审查信息列表
  getMinisterialTechnicalReviewPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/municipal/technology/approval/log/" + logId,
      data: {}
    });
  },
  // 读取-省级备案列表
  getProvincialFilingPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/province/filing/review/log/" + logId,
      data: {}
    });
  },
  // 读取-部级备案审查列表
  getMinisterialFilingPageV2(logId) {
    return request.Get({
      url: "/v1/cp/planning/municipal/filing/review/log/" + logId,
      data: {}
    });
  },
  // 导出-列表
  exportList(data) {
    return request.DownloadPost({
      url: "/v1/cp/planning/declare/export",
      data: data
    });
  },
  // 导出-列表-专家
  exportListExpert(data) {
    return request.DownloadPost({
      url: "/v1/cp/planning/declare/expert/export",
      data: data
    });
  },

  // 导出-名镇名村申报-基础数据表格
  exportDeclareBaseInfo(declareId) {
    return request.Download({
      url: "/v1/hcc/declare/table/base/export/" + declareId,
      data: {
        type: "file",
        file: {}
      }
    });
  },

  // 导出-名镇名村申报-申请数据表格
  exportDeclareApplyInfo(declareId) {
    return request.Download({
      url: "/v1/hcc/declare/table/apply/export/" + declareId,
      data: {
        type: "file",
        file: {}
      }
    });
  },
  // 导出-名镇名村申报-自评表格
  exportDeclareEvaluationInfo(declareId) {
    return request.Download({
      url: "/v1/hcc/declare/table/evaluation/export/" + declareId,
      data: {
        type: "file",
        file: {}
      }
    });
  },

  // 部级地图-名城规划文件分组数量统计
  mapStatistics(data) {
    return request.Post({
      url: `/v1/cp/planning/declare/map/drawing/statistics`,
      data: data
    });
  },
  // 部级地图-名城地图坐标点数量统计
  mapPoints(data) {
    return request.Post({
      url: `/v1/cp/planning/declare/map/point/statistics`,
      data: data
    });
  },
  // 部级地图-名城规划文件最新列表
  mapDrawingList(data) {
    return request.Post({
      url: `/v1/cp/planning/declare/map/drawing/list`,
      data: data
    });
  },
  // 部级地图-获取指定省下面有数据的市县
  getAllCityCounty(data) {
    return request.Post({
      url: `/v1/cp/planning/declare/map/citycounty/list`,
      data: data
    });
  },
  // 解析地图数据
  getShpData(fileUid) {
    return request.Get({
      url: `/v1/file/gls/shp/${fileUid}`,
      data: {}
    });
  },
  // 部级地图-获取描区域数据
  getMapPolygon(data) {
    return request.Post({
      url: `/v1/cp/planning/declare/map/shp/pel`,
      data: data
    });
  }
};
